import React, { useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import SingleProject from "./SingleProject";
import projects from "../utils/projects";

const Projects = () => {
  const projectList = projects;

  //switch the spans for the headings to h3s? change their display to block?
  return (
    <>
      <div>
        <section className="projectsSectionContainer">
          <span className="project">Title</span>
          <span className="project project-technologies">Primary Technologies</span>
          <span className="project">Description</span>
          <span className="project codeColumn projectLinks">Links</span>
        </section>
      </div>

      <section>
        {projectList.map((project, i) => {
          return (
            <>
              <Link to={`/project/${i}`}>
                <article key={i}>
                  <hr></hr>
                  <div className="projectsContainer">
                    <span className="project">{project.title}</span>
                    <span className="project project-technologies">{project.technologies}</span>
                    <span className="project">{project.description[0]}</span>
                    <span className="projectLinks">
                      <a className="projectLink" href={project.links[0]}>
                        <span className="underline">{project.linkTitle}</span>
                      </a>
                    </span>
                  </div>
                </article>
              </Link>
            </>
          );
        })}
      </section>
    </>
  );
};



export default Projects;
