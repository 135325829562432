// const projects = [
//   {
//     title: "Visual Global Knowledge Graph - AR",
//     technologies: "XCode, Swift, AR Kit, GDelt API, OpenStreetMap API",
//     description: [
//       "Mobile augmented reality application that renders news content using geolocation",
//       "Mobile application for art exhibits. Built using React Native, Django, Docker, Postgres, etc.",
//     ],
//     imageLink: "../assets/mkBooks.gif",
//     role: "Architect + Fullstack Engineer + UX Designer",
//     links: ["https://github.com/santiagojon/VTS-Board"],
//     pageLink: "",
//     year: 2025,
//   },
//   {
//     title: "Listen",
//     technologies: "React Native, Django, Postgres, Docker, Redis, Google Maps API",
//     description: [
//       "Mobile audio application for indoor and outdoor art exhibits",
//       "Mobile audio application for art exhibits. Built using React Native, Django, Docker, Postgres, etc.",
//     ],
//     imageLink: "../assets/mkBooks.gif",
//     role: "Architect + Fullstack Engineer + UX Designer",
//     links: ["https://github.com/santiagojon/VTS-Board"],
//     pageLink: "",
//     year: 2024,
//   },
//   {
//     title: "VTS Board",
//     technologies: "React, Express, Node.js, Clarifai API",
//     description: [
//       "Educational tool for photo and art teachers",
//       "Educational tool for photo and art teachers, primarily focusing on using visual thinking strategies (VTS) exercises. Built using React, Tailwind, Websockets, Excalidraw API, Node.js, Express, and Postgres.",
//     ],
//     imageLink: "",
//     role: "Fullstack Engineer",
//     links: ["https://github.com/santiagojon/VTS-Board"],
//     pageLink: "",
//     year: 2022,
//   },
//   {
//     title: "Proxima",
//     technologies: "React, Three.js, Express, Node.js, Postgres",
//     description: [
//       "3D educational application that allows users to explore exoplanets and their star systems",
//       "3D educational application that dynamically renders the over 5000 known exoplanets from NASA's Exoplanet TAP. Built using React, Redux, Three.js, React Three Fiber, Node.js, Express, Postgres, and React Spring.",
//     ],
//     imageLink: "../assets/proximaGif.gif",
//     role: "Fullstack Engineer",
//     links: [
//       "https://github.com/santiagojon/Proxima",
//       "https://proxima-worlds.herokuapp.com/",
//     ],
//     pageLink: "",
//     year: 2022,
//   },


// ];

// export default projects;


import proximaImg from "../assets/proximaGif.gif";
import mkImg from "../assets/mkBooks.gif";
import vtsImg from "../assets/vtsBoard.png";



const projects = [
  {
    title: "Visual Global Knowledge Graph - AR",
    technologies: "XCode, Swift, AR Kit, GDelt API, OpenStreetMap API",
    description: [
      "Mobile augmented reality application that renders news imagery and content using geolocation",
      "Inspired by the cutting-edge capabilities of GDELT's global news imagery analysis through Google Cloud Vision API, I am in the process of developing an iOS augmented reality application to harness these technologies for real-time news visualization. By integrating AI-driven annotations like content-based geolocation and OCR, this app aims to offer users an immersive and informative view of world events, setting the stage for future expansion to wearable technologies. \nBuilt using XCode, Swift, AR Kit, GDelt API, and the OpenStreetMap API",
    ],
    imageLink: null,
    role: "Architect, fullstack engineer, and UX designer",
    links: [],
    linkTitle: '',
    pageLink: "",
    year: '2024 - Present',
    projectImg: null,
    slug: 'visual-global-knowledge-graph'
  },
  {
    title: "Listen",
    technologies: "React Native, Django, Postgres, Docker, Redis, Google Maps API",
    description: [
      "Mobile audio application for indoor and outdoor art exhibits",
      "Listen is a mobile audio application for indoor and outdoor art exhibits. Inspired by my time working within arts organizations, I wanted to leverage technology to add an additional layer to viewer experiences, which are often limited to the artwork itself and a caption underneath. Users can access additional information about artworks, projects, artists, take guided tours, and learn about additional exhibit information such as events. Built using React Native, Django, Docker, Postgres, Redis, and the Google Maps API",
    ],
    imageLink: null,
    role: "Architect, fullstack engineer, and UX designer",
    links: [],
    linkTitle: '',
    pageLink: "",
    year: '2024 - Present',
    projectImg: null,
    slug: 'listen'
  },
  {
    title: "Photogrammetry Models",
    technologies: "Drone, Handheld cameras, LiDAR, Metashape, Unity, Three.js",
    description: [
      "Photogrammetry models captured using various approaches and rendered using several technologies",
      "I am interested in creating photogrammetry models for various use cases but particularly in aiding storytelling. I have made object and space captures using both handheld cameras and drones and have processed them using Metashape and Apple's Object Capture API. I am currently in the process of optimizing my gltf models to add here on this site.",
    ],
    imageLink: null,
    role: "Photogrammetry specialist and software engineer",
    links: ["https://github.com/santiagojon/photogrammetry_model_test_sandbox"],
    linkTitle: 'Github - Sandbox Environment',
    pageLink: "",
    year: '2024 - Present',
    projectImg: null,
    slug: 'photogrammetry-models'
  },
  {
    title: "VTS Board",
    technologies: "React, Express, Node.js, Firebase, Websockets, Excalidraw API",
    description: [
      "Educational tool for photo and art teachers",
      "Educational tool for photo and art teachers, primarily focusing on using visual thinking strategies (VTS) exercises. Built using React, Tailwind, Websockets, Excalidraw API, Node.js, Express, and Postgres.",
    ],
    imageLink: "/static/media/vtsBoard.695f0453706e9edf6441.png",
    role: "Fullstack engineer",
    links: ["https://github.com/santiagojon/vts_board"],
    linkTitle: 'Github',
    pageLink: "",
    year: 2022,
    projectImg: vtsImg,
    projectImgAlt: "VTS Board application screenshot",
    slug: 'vts-board'
  },
  {
    title: "Proxima",
    technologies: "React, Three.js, Express, Node.js, Postgres",
    description: [
      "3D educational application that allows users to explore exoplanets and their star systems",
      "3D educational application that dynamically renders the over 5000 known exoplanets from NASA's Exoplanet TAP. Built using React, Redux, Three.js, React Three Fiber, Node.js, Express, Postgres, and React Spring.",
    ],
    imageLink: "/static/media/proximaGif.217ebe735fbb77ecbb77.gif",
    role: "Fullstack engineer",
    links: [
      "https://github.com/santiagojon/Proxima",
      "https://proxima-worlds.herokuapp.com/",
    ],
    linkTitle: 'Github',
    pageLink: "",
    year: 2022,
    projectImg: proximaImg,
    projectImgAlt: "Proxima application screenshot",
    slug: 'proxima'
  },
];

export default projects;

