import React from "react";
import LinkBar from "./ui/LinkBar";
import Technologies from "./Technologies";

import CallMadeIcon from "@mui/icons-material/CallMade";

const AboutPage = () => {
  return (
    <div id="aboutSection">
      <div id="aboutContainer">
      I'm a full stack software engineer based in New York City. Primarily interested in working in news and education tech and in mission-driven 
      organizations, I am currently working at Core Education as a software engineer, after building a career at the intersection of community-based nonprofits, education, design, and journalism.
        {/* I'm a full stack software engineer based in New York City. Building on a
        career in community-based nonprofits, education, and journalism, I
        decided to blend my love of coding and communication with my interests
        in design and style, and dive into web app development. Creating spaces
        in our digital world that are fast, engaging, and reliable has become a
        passion ever since. */}
        <br></br> <br></br>
        Always learning and open to new technologies, here's what I'm currently
        using or have recent experience with:<br></br> <br></br>
        {/* <p className="aboutContact">Email: jonwsantiago@gmail.com</p> */}
      </div>
      <Technologies />
    </div>
  );
};

export default AboutPage;

//With a background

//I have worked with industry leading education, journalism, and youth development organizations, and am passionate about
//creating modern full stack applications to help my clients achieve their highest goals.

//I'm a full stack software engineer based in New York City. Building on a career in community-based nonprofits,
//education, and journalism, I decided to blend my love of coding and communication with my interests in design
//and style, and dive into web app development. Creating spaces in our digital world that are fast, engaging, and reliable
//has become a passion ever since.
